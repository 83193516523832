<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('concernManagement.concern_officer') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Organization" vid="org_id">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('externalTradeTraiff.office')}}
              </template>
              <b-form-select
                plain
                v-model="search.org_id"
                :options="orgList"
                id="org_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Office Type" vid="office_type_id">
              <b-form-group
                label-for="office_type_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('license_management.office_type')}}
              </template>
              <b-form-select
                plain
                v-model="search.office_type_id"
                :options="officeTypeList"
                id="office_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Office" vid="office_id">
              <b-form-group
                label-for="office_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.office')}}
              </template>
              <b-form-select
                plain
                v-model="search.office_id"
                :options="officeList"
                id="office_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Designation" vid="designation_id">
              <b-form-group
                label-for="designation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.designation')}}
              </template>
              <b-form-select
                plain
                v-model="search.designation_id"
                :options="designationList"
                id="designation_id"
                @change="getUser"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Officer Name" vid="employee_id">
              <b-form-group
                label-for="employee_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('priceMonitoring.officer_name')}}
              </template>
              <b-form-select
                plain
                v-model="search.employee_id"
                :options="userList"
                id="employee_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
               <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col sm="3">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('concernManagement.concern_officer') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                          <b-form-checkbox
                            :id="'checkbox-' + field.label_en"
                            v-model="field.show"
                            :name="'checkbox-' + field.label_en"
                            value=1
                            unchecked-value=0
                          >
                            {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                          </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-if="data.item.is_consultant === 0" variant="iq-bg-success mr-1" size="sm" @click="makeConsultant(data.item)" class="action-btn edit" :title="$t('concernManagement.make_cunsultant')"><i class="ri-creative-commons-by-line"></i></b-button>
                    <span v-if="data.item.is_consultant === 1"><b-badge pill variant="info">{{ $t('concernManagement.make_cunsultant') }}</b-badge></span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { createConcernOfficerList, officeListByDesignationApi, createMakeConsultant, makeConsultantList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import i18n from '@/i18n'

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.externalTradeTraiff.office, label_bn: i18n.messages.bn.externalTradeTraiff.office, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
  { label_en: i18n.messages.en.license_management.office_type, label_bn: i18n.messages.bn.license_management.office_type, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.globalTrans.office, label_bn: i18n.messages.bn.globalTrans.office, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.organogram.designation, label_bn: i18n.messages.bn.organogram.designation, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.priceMonitoring.officer_name, label_bn: i18n.messages.bn.priceMonitoring.officer_name, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.globalTrans.action, label_bn: i18n.messages.bn.globalTrans.action, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } }
]

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        org_id: 4,
        office_type_id: 0,
        office_id: 0,
        designation_id: 0,
        employee_id: 0,
        limit: 20
      },
      items: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      userList: [],
      userLoading: false,
      viewitemId: 0,
      actions: {
        forward: false,
        edit: false,
        details: true,
        toogle: false,
        delete: false
      },
      consultantData: []
    }
  },
  created () {
    this.loadConsultantData()
    this.loadData()
    this.officeTypeList = this.getOfficeTypeList(4)
     this.labelData = this.labelList
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
      } else {
        this.officeList = []
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      } else {
        this.designationList = []
      }
    },
    currentLocale: function () {
      if (this.search.org_id) {
        this.officeTypeList = this.getOfficeTypeList(this.search.org_id)
      }
      if (this.search.office_type_id) {
        this.officeList = this.getOfficeList(this.search.office_type_id)
      }
      if (this.search.office_id) {
        this.designationList = this.getDesignationList(this.search.office_id)
      }
      if (this.search.designation_id) {
        this.userList = this.getUser(this.search.designation_id)
      }
    }
  },
  computed: {
    orgList () {
      const list = this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.value === 4)
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    labelList: function () {
      const dataList = defaultColumn
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          const data = { value: item.label_en, text: item.label_bn }
          return Object.assign({}, item, data)
        } else {
          const data = { value: item.label_en, text: item.label_en }
          return Object.assign({}, item, data)
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        const labelData = {}
        labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
        return Object.assign(item, labelData)
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'org_name_bn' },
          { key: 'office_type_name_bn' },
          { key: 'office_name_bn' },
          { key: 'desingation_name_bn' },
          { key: 'name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'org_name' },
          { key: 'office_type_name' },
          { key: 'office_name' },
          { key: 'desingation_name' },
          { key: 'name' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('globalTrans.forward') + ' ' + this.$t('globalTrans.entry') : this.$t('globalTrans.forward') + ' ' + this.$t('globalTrans.modify')
    },
    viewTitle () {
      return this.$t('globalTrans.forward') + ' ' + this.$t('globalTrans.details')
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
  },
  methods: {
    makeConsultant (item) {
      window.vm.$swal({
        title: window.vm.$t('concernManagement.consultant_status'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.makeConsultantChangeStatus(item)
        }
      })
    },
    async makeConsultantChangeStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          employee_id: item.id,
          org_id: item.org_id,
          office_type_id: item.office_type_id,
          office_id: item.office_id,
          designation_id: item.designation_id,
          create_con_status: 1
        })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.postData(tradeTariffServiceBaseUrl, createMakeConsultant, params)
      if (result.success) {
        window.vm.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        } else {
          window.vm.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.$store.dispatch('mutateCommonProperties', { listReload: true })
      }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
      return officeList
    },
    getDesignationList (officeId) {
      const list = this.$store.state.CommonService.commonObj.assignDesignationList.filter(item => item.office_id === officeId && item.office_type_id === this.search.office_type_id && item.org_id === this.search.org_id)
      return list.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getUser () {
      this.getUserList(this.search.designation_id)
    },
    getUserList (designationId) {
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, `${officeListByDesignationApi}/${designationId}`).then(response => {
        const userList = response.filter(obj => obj.value !== this.$store.state.Auth.authUser.user_id).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.value,
            text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          })
        })
        this.userList = userList
        this.userLoading = false
        return userList
      })
    },
    forward (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, createConcernOfficerList, params)
      if (result.success) {
        const listData = result.data.data.data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.org_id))
          const officeTypebj = this.$store.state.CommonService.commonObj.officeTypeList.find(type => type.value === parseInt(item.office_type_id))
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(type => type.value === parseInt(item.office_id))
          const desingationObj = this.$store.state.CommonService.commonObj.designationList.find(desingation => desingation.value === parseInt(item.designation_id))
          const orgData = {}
          const officeTypeData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_name = orgObj.text_en
            orgData.org_name_bn = orgObj.text_bn
          } else {
            orgData.org_name = ''
            orgData.org_name_bn = ''
          }
          if (typeof officeTypebj !== 'undefined') {
            officeTypeData.office_type_name = officeTypebj.text_en
            officeTypeData.office_type_name_bn = officeTypebj.text_bn
          } else {
            officeTypeData.office_type_name = ''
            officeTypeData.office_type_name_bn = ''
          }
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_name = officeObj.text_en
            officeData.office_name_bn = officeObj.text_bn
          } else {
            officeData.office_name = ''
            officeData.office_name_bn = ''
          }
          const designationData = {}
          if (typeof desingationObj !== 'undefined') {
            designationData.desingation_name = desingationObj.text_en
            designationData.desingation_name_bn = desingationObj.text_bn
          } else {
            designationData.desination_name = ''
            designationData.desingation_name_bn = ''
          }
          // this.loadConsultantData()
          const isConsultant = this.consultantData.includes(item.id) ? 1 : 0
          return Object.assign({}, item, { serial: index }, orgData, officeTypeData, officeData, designationData, { is_consultant: isConsultant })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async loadConsultantData () {
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, makeConsultantList)
      if (result.success) {
        this.consultantData = result.data
      } else {
        this.consultantData = []
      }
    }
  }
}
</script>
